exports.components = {
  "component---src-layout-work-details-tsx": () => import("./../../../src/layout/work-details.tsx" /* webpackChunkName: "component---src-layout-work-details-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-for-fun-tsx": () => import("./../../../src/pages/for-fun.tsx" /* webpackChunkName: "component---src-pages-for-fun-tsx" */),
  "component---src-pages-hire-me-tsx": () => import("./../../../src/pages/hire-me.tsx" /* webpackChunkName: "component---src-pages-hire-me-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-works-tsx": () => import("./../../../src/pages/works.tsx" /* webpackChunkName: "component---src-pages-works-tsx" */)
}

